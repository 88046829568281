<template>
  <div>
    <!--    导航栏-->
    <tabs @parentEvent="btn"></tabs>
    <div class="imgs">
      <div class="solution-banner-container">
        <div class="page-content"></div>
      </div>
      <!--      <img src="" alt="" style="min-height: 450px;height: 450px">-->
      <img
        src="https://bluexii.oss-cn-shanghai.aliyuncs.com/png/ee238519471661d1643deee2a53d2638.png"
        alt=""
      />
      <img
        src="https://bluexii.oss-cn-shanghai.aliyuncs.com/png/be9cadf1b2ac5185d9f99f39ba173fce.png"
        alt=""
      />
      <img
        src="https://bluexii.oss-cn-shanghai.aliyuncs.com/png/3ae2dc92d6034265a63948d99452226e.png"
        alt=""
      />
      <img
        src="https://bluexii.oss-cn-shanghai.aliyuncs.com/png/e4b4f97c9e4e60a26d03c1495beaa3c8.png"
        alt=""
      />
      <img
        src="https://bluexii.oss-cn-shanghai.aliyuncs.com/png/8fcbe2f18be92488b450a9f1b8bfb5c0.png"
        id="botton"
        alt=""
      />

      <!--      <span class="page-footer-top-logo"><a href="#">LOGO文件下载</a></span>-->
    </div>
    <footers></footers>
    <!--   end 导航栏-->

    <!--        中间部分-->
    <!--    <div class="main">-->
    <!--      <div class="solution-banner-container ">-->
    <!--        <div class="page-content">-->
    <!--          <div class="solution-banner-content">-->
    <!--            <div class="solution-banner-title">计算摄影解决方案</div>-->
    <!--            <div class="solution-banner-desc">近年，随着“换机红利、技术红利和消费红利”的消散，以智能手机为代表的智能设备集体迈进“红利消退期”，产品同质化严重，新机不再酷。AI和5G的前夜，在“影像处理、影像画质、多摄拍摄和视频录制”等方面的提升，成为业界新的发力点。</div>-->
    <!--          </div>-->
    <!--          <a href="#" class="button">方案咨询</a>-->
    <!--        </div>-->

    <!--      </div>-->
    <!--      <div class="card-dark">-->
    <!--          <h1>发展趋势</h1>-->
    <!--          <div class="solution page-content">-->
    <!--               <div class="solution-item" v-for="item in 4">-->
    <!--                 <div class="solution-item-img">-->
    <!--                   <img src="../../assets/solve_Device_Authentication.png" alt="">-->
    <!--                 </div>-->
    <!--                 <div class="solution-item-right">-->
    <!--                   <div class="solution-item-right-title">由屏上到屏下</div>-->
    <!--                   <div class="solution-item-right-introduce">智能手机向全面屏演进，基于屏下模组的生物认证需求日渐增多，屏下指纹指纹识已逐渐普及</div>-->
    <!--                 </div>-->
    <!--               </div>-->
    <!--          </div>-->
    <!--      </div>-->
    <!--    </div>-->
    <!--    &lt;!&ndash;        end 中间部分&ndash;&gt;-->
    <!--    &lt;!&ndash;    底部&ndash;&gt;-->
    <!--    <footers></footers>-->
    <!--    end 底部-->
  </div>
</template>

<script>
import tabs from "@/components/tabs";
import footers from "@/components/footers";

export default {
  props: [],
  components: {
    tabs,
    footers
  },

  data() {
    return {};
  },

  mounted() {
    // this.height =  window.getComputedStyle(this.$refs.element).scrollHeight;
    // let middle = this.$refs["element"];
    // this.height = middle.scrollHeight;
  },
  methods: {
    btn() {
      // document.documentElement.scrollTop = this.height;//不需要加单位
      document.getElementById("botton").scrollIntoView();
    }
  }
};
</script>

<style lang="less" scoped>
img {
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  display: flex;
}
.imgs {
  position: relative;

  img {
    width: 100%;
    height: 100%;
  }

  .page-footer-top-logo {
    position: absolute;
    bottom: 130px;
    left: 409px;

    a {
      color: #808080;
      text-decoration: none;
    }
    a:hover {
      color: #0f2e56;
    }
  }
}

.solution-banner-container {
  height: 668px;
  background-color: #000;
  background-position: center;
  // background-size: 100% 100%;
  background-repeat: no-repeat;
  background-image: url("https://bluexii.oss-cn-shanghai.aliyuncs.com/png/116e970cdbf579dd80583c8c45956a0a.png");
  .solution-banner-content {
    margin: auto;
    padding-top: 110px;
    color: #fff;
    width: 780px;

    .solution-banner-title {
      font-size: 32px;
      line-height: 60px;
      font-weight: 600;
      margin-bottom: 8px;
    }

    .solution-banner-desc {
      font-size: 16px;
      line-height: 25px;
      margin-bottom: 30px;
    }
  }
}

//.page-content {
//  max-width: 1160px;
//  margin: 0 auto;
//}
//.button{
//  display: inline-block;
//  width: 180px;
//  height: 46px;
//  line-height: 46px;
//  border-radius: 1px;
//  font-size: 16px;
//  text-align: center;
//  transition: all .1s linear 0s;
//}
//a{
//  text-decoration: none;
//}
//.main {
//
//  .solution-banner-container {
//    min-height: 450px;
//    background-color: #000;
//    background-position: center;
//    background-size: auto 100%;
//    background-repeat: no-repeat;
//    background-image: url("../../assets/solve_bg.jpg");
//
//  .solution-banner-content{
//    padding-top: 110px;
//    color: #fff;
//    width: 650px;
//
// .solution-banner-title{
//   font-size: 32px;
//   line-height: 60px;
//   font-weight: 600;
//   margin-bottom: 8px;
// }
//    .solution-banner-desc{
//      font-size: 16px;
//      line-height: 25px;
//      margin-bottom: 30px;
//    }
//
//
//  }
//  a{
//    background-color: #fff;
//    color: #0e57a2;
//    border: 1px solid #fff;
//  }
//
//  }
//  .card-dark{
//    background: #f2f3f5;
//    padding: 80px 0;
//
//    h1{
//      font-size: 32px;
//      font-weight: 600;
//      color: #333;
//      line-height: 60px;
//      text-align: center;
//    }
//    .solution{
//      display: flex;
//      flex-wrap: wrap;
//      justify-content: space-between;
//      margin-top: 18px;
//    }
//    .solution-item{
//      width: 570px;
//      padding: 21px 40px 30px 21px;
//      display: flex;
//      align-items: center;
//      margin-bottom: 8px;
//  box-sizing: border-box;
//      .solution-item-img{
//        width: 90px;
//        height: 90px;
//        margin-right: 10px;
//
//        img{
//          width: 100%;
//          height: 100%;
//        }
//      }
//      .solution-item-right{
//        width: 409px;
//        display: flex;
//        justify-content: center;
//        flex-direction: column;
//
//        .solution-item-right-title{
//          margin-bottom: 8px;
//          line-height: 28px;
//          font-size: 18px;
//          font-weight: 600;
//          color: #333;
//        }
//        .solution-item-right-introduce{
//          line-height: 24px;
//          font-size: 14px;
//          color: #666;
//        }
//      }
//    }
//    .solution-item:hover{
//      box-shadow: 0 2px 10px 0 rgba(10,35,73,.09);
//      border-radius: 1px
//    }
//    .solution-item:nth-child(odd) {
//      margin-right: 20px;
//    }
//  }
//
//}
</style>
