<template>
  <div>
    <!--     底部-->
    <div class="footer">
      <div class="page-footer">
        <div class="page-footer-top page-content">
          <div class="page-footer-top-1">
            <img src="../assets/logo/logo.svg" alt="" class="logo" />
            <i></i>
            <img src="../assets/logo/ximu.svg" alt="" class="xm" />

            <i></i>
            <img class="yuan" src="../assets/logo/yuan.svg" alt="" />
          </div>
          <span class="x"></span>
          <div class="txt">
            <div class="title">联系我们</div>
            <div class="txt2">
              <span>联系方式:</span>{{ getList.Contact }}/{{ getList.Tel }}
            </div>
            <div class="txt2"><span>企业邮箱:</span>{{ getList.Email }}</div>
            <div class="txt2">
              <span>公司地址:</span>{{ getList.ContactAddress }}
            </div>
          </div>
          <div class="img">
            <img src="../assets/xcx.png" alt="" />
            <div>请扫描上方小程序码查看企业信息</div>
          </div>
        </div>
        <div class="page-footer-buttom">
          <div>
            Copyright © 2020 宁波蓝犀信息科技有限公司 |
            <a href="https://beian.miit.gov.cn/">浙ICP备18050022号-1</a>
          </div>
        </div>
      </div>
    </div>
    <!--     end 底部-->
  </div>
</template>

<script>
export default {
  name: "footers",
  props: {},

  components: {},

  data() {
    return {
      getList: ""
    };
  },

  methods: {},

  mounted() {
    var _this = this;
    this.$axios
      .post("https://api.bluexii.cn/xiimol/v1/public/corpget", {})
      .then(function(res) {
        // console.log(res.data.Data);
        _this.getList = res.data.Data;
      })
      .catch(function(error) {
        console.log(error);
      });
  }
};
</script>

<style lang="less" scoped>
.imgs {
  position: relative;

  img {
    width: 100%;
    height: 100%;
  }
}

.page-content {
  max-width: 1160px;
  margin: 0 auto;
}

//// 底部联系我们
.footer {
  background: #f9f9f9;
}

.page-footer {
  a {
    font-size: 14px;
    color: #666 !important;
    line-height: 20px;
    text-decoration: none;
  }

  .page-footer-top {
    padding: 65px 0 112px;
    display: flex;
    justify-content: space-around;
    border-bottom: 1px solid rgba(208, 208, 208, 0.5);
    //max-width: 1160px;
    .page-footer-top-1 {
      margin-right: 100px;
      display: flex;
      justify-content: center;
      align-items: center;

      .logo {
        height: 32px;
      }
      .xm {
        //height: 40%;
        //object-fit: cover;
        //margin-top: -8px;
        height: 35px;
      }
      .yuan {
        margin-top: -2px;
        height: 34px;
      }
      i {
        display: inline-block;
        height: 14px;
        width: 1px;
        background-color: #d8d8d8;
        margin: 0 16px;
        //margin-bottom: 7px;
      }
    }

    .x {
      width: 1px;
      height: 157px;
      background-color: #eeeeee;
    }

    .txt {
      .title {
        font-size: 18px;
        font-weight: 600;
        color: #333333;
        line-height: 25px;
        margin-bottom: 32px;
      }

      .txt2 {
        margin-bottom: 16px;
        color: #333;
        font-size: 14px;

        span {
          color: #333;
          padding-right: 10px;
        }
      }
    }

    .img {
      text-align: center;

      img {
        margin-bottom: 22px;
      }

      div {
        color: #666666;
        font-size: 14px;
      }
    }
  }

  .page-footer-buttom {
    font-size: 12px;
    max-width: 1160px;
    margin: 0 auto;
    padding: 14px 0;
    color: #666;
    text-align: center;

    a {
      line-height: 20px;
      font-size: 12px;
      text-decoration: none;

      img {
        vertical-align: middle;
        margin-right: 5px;
      }

      span {
        vertical-align: middle;
      }
    }

    a:hover {
      text-decoration: underline;
      color: #0da0ec !important;
    }

    .top {
      display: inline-block;
      margin-top: 5px;
    }
  }
}

// end 底部联系我们
</style>
