<template>
  <div>
    <!-- 导航条-->
    <div
      class="main "
      :class="isshadow === true ? 'shadow' : ''"
      :style="{ backgroundColor: yangse }"
      @mouseover="changeActive($event)"
      @mouseout="removeActive($event)"
    >
      <div class="contents">
        <el-menu
          :default-active="this.$route.path"
          router
          class="el-menu-demo"
          mode="horizontal"
        >
          <el-menu-item class="menu1"
            ><img @click="imgClick" class="logo" :src="img" alt="" />
          </el-menu-item>
          <el-menu-item index="/"
            ><span :class="{ Color: activeColor }">公司介绍</span></el-menu-item
          >
          <el-submenu index="2">
            <template slot="title"
              ><span :class="{ Color: activeColor }">解决方案</span></template
            >
            <div
              class="submenu"
              @mouseover="submenuMouseover($event)"
              @mouseout="submenumouseout($event)"
            >
              <!-- <router-link replace v-for="title in retail" :key="index"
              :to="{name:'Solution_retail',params :{Content:title.Content}}" tag="div"> -->
              <el-menu-item
                v-for="(title, index) in retail"
                :key="index"
                @mouseover="submenuMouseover($event)"
                @mouseout="submenumouseout($event)"
                @click="jumpTo(title)"
              >
                {{ title.Title }}
              </el-menu-item>
              <!-- </router-link> -->
            </div>
          </el-submenu>
          <el-menu-item index="/cooperation"
            ><span :class="{ Color: activeColor }">园区案例</span></el-menu-item
          >
          <el-menu-item index="/news"
            ><span :class="{ Color: activeColor }">企业新闻</span></el-menu-item
          >
          <el-menu-item @click="btns"
            ><span :class="{ Color: activeColor }">联系我们</span></el-menu-item
          >
          <el-menu-item index="/app"
            ><span :class="{ Color: activeColor }">i招犀</span></el-menu-item
          >
        </el-menu>
      </div>
    </div>
  </div>
</template>

<script>
// import { async } from "q";

let top;
export default {
  name: "tabs",
  props: {
    height: Number
  },
  components: {},

  data() {
    return {
      activeIndex: "1",
      yangse: "",
      visible: false,
      id: "",
      // a: this.paths,
      //  解决方案标题
      retail: [],
      area: [],
      // 监听宽度
      screenWidth: document.body.clientWidth,
      activeColor: true,
      img: require("../assets/logo/logo2.png"),
      top: "",
      isshadow: true
    };
  },
  watch: {
    // screenWidth(newValue) {
    //   // 为了避免频繁触发resize函数导致页面卡顿，使用定时器
    //   if (!this.timer) {
    //     // 一旦监听到的screenWidth值改变，就将其重新赋给data里的screenWidth
    //     this.screenWidth = newValue;
    //     this.timer = true;
    //     setTimeout(() => {
    //       console.log(this.screenWidth);
    //       this.timer = false;
    //     }, 400);
    //   }
    //   console.log(this.screenWidth);
    // }
  },
  mounted() {
    var _this = this;
    // 解决方案接口
    this.$axios
      .get(
        " https://api.bluexii.cn/xiimol/v1/public/corpcmscontentlist/73/0/10",
        {
          // 还可以直接把参数拼接在url后边
        }
      )
      .then(function(res) {
        for (let i = 0; i < res.data.Data.length; i += 1) {
          _this.retail.push(res.data.Data[i]);
        }
      })
      .catch(function(error) {
        console.log(error);
      });
    // 监听页面滚动事件
    window.addEventListener("scroll", () => {
      top =
        document.documentElement.scrollTop ||
        document.body.scrollTop ||
        window.pageYOffset;
      this.top = top;
      if (top < this.height) {
        this.yangse = "transparent";
        this.img = require("../assets/logo/logo1.png");
        this.isshadow = false;
        if (this.$route.path === "/") {
          this.activeColor = true;
        } else {
          this.activeColor = false;
        }
      } else {
        this.yangse = "";
        this.activeColor = false;
        this.img = require("../assets/logo/logo2.png");
        this.isshadow = true;
      }
    });
    //监听鼠标切换事件
    if (this.$route.path === "/") {
      this.yangse = "transparent";
      this.activeColor = true;
      this.img = require("../assets/logo/logo1.png");
      this.isshadow = false;
    } else {
      this.img = require("../assets/logo/logo2.png");
      _this.activeColor = false;
    }
    // window.addEventListener("mousewheel", this.handleScroll);
    // let _this=this

    // window.onresize = () => {
    //   this.screenWidth = document.body.clientWidth;
    // };
    // console.log(this.screenWidth);
  },
  methods: {
    // 鼠标移入加入class
    changeActive() {
      this.img = require("../assets/logo/logo2.png");
      this.activeColor = false;
    },
    // 鼠标移出加入class
    removeActive() {
      this.img = require("../assets/logo/logo1.png");
      this.activeColor = true;
      if (top > 0) {
        this.img = require("../assets/logo/logo2.png");
        this.activeColor = false;
      } else {
        this.img = require("../assets/logo/logo1.png");
        this.activeColor = true;
      }
      if (this.$route.path !== "/") {
        this.activeColor = false;
        this.img = require("../assets/logo/logo2.png");
      } else {
        if (top > 0) {
          this.activeColor = false;
          this.img = require("../assets/logo/logo2.png");
          this.yangse = "#fff";
        } else {
          this.activeColor = true;
          this.img = require("../assets/logo/logo1.png");
          this.yangse = "transparent";
        }
      }
    },
    submenuMouseover() {
      this.yangse = "#fff";
      this.img = require("../assets/logo/logo2.png");
      this.activeColor = false;
    },
    submenumouseout() {
      if (this.$route.path !== "/") {
        this.activeColor = false;
        this.img = require("../assets/logo/logo2.png");
        this.yangse = "#fff";
      } else {
        if (this.top > 0) {
          console.log(1);
          console.log(this.top);
          this.yangse = "#fff";
          this.activeColor = false;
          this.img = require("../assets/logo/logo2.png");
        } else {
          this.yangse = "transparent";
          this.activeColor = true;
          this.img = require("../assets/logo/logo1.png");
        }
      }
    },
    // logo跳转
    imgClick() {
      // ``;
      this.$router.push({ path: "/" });
    },
    jumpTo(title) {
      this.$router.push({
        name: "Solution_retail",
        path: "/solution_retail",
        query: { Content: title.Content }
      });
      // {name:'Solution_retail',params :{Content:title.Content}}
    },

    btns() {
      // this.id = this.$route.path;
      // setTimeout(() => {
      //   this.id = "";
      // }, 500);
      this.$emit("parentEvent");
    }
  }
};
</script>
<style>
/* 注意：这两个样式会影响其他属性*/
.el-menu--popup-bottom-start {
  margin-top: 0;
  box-sizing: border-box;
}

.el-menu--popup {
  padding: 0;
  box-shadow: none;
}
</style>
<style lang="less" scoped>
.main {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  z-index: 1000;
  transition: top 0.3s linear;
  background-color: #fff;
}

.shadow {
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.08);
}

.contents {
  display: block;
  height: 64px;
  line-height: 64px;
  width: 1160px;
  max-width: 1160px;
  margin: 0 auto;
}

/*导航栏*/
/deep/ .el-submenu__title i,
/deep/ .el-menu--horizontal > .el-submenu .el-submenu__title,
/deep/ .el-menu--horizontal > .el-menu-item {
  color: #000;
  border: none;
}

.Color {
  color: #fff !important;
  z-index: 999;
}

/deep/ .el-menu-demo {
  height: 64px;
  line-height: 64px;
  border: none;
  font-size: 14px;
  font-weight: 600;
  background-color: transparent !important;

  .is-active {
    position: relative;
    background-color: transparent;
  }

  .is-active:before {
    content: "";
    position: absolute;
    bottom: 12px;
    left: 50%;
    width: 20px;
    height: 4px;
    transform: translateX(-50%);
    //background: #fff;
    box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.08);
    background-color: #fff;
    border-radius: 5px;
  }

  /deep/.el-menu-item[data-v-c58ee6e4]:not(.is-disabled):focus {
    //display: none !important;
    //color: none;
    color: #000;
  }

  .logo {
    width: 97px;
    height: 23px;
    margin-top: -4px;
  }

  .menu1 {
    padding-left: 0;
  }

  /deep/ .el-submenu__icon-arrow {
    display: none;
  }

  a {
    text-decoration: none;
  }
}

/deep/ .el-menu--horizontal:hover {
  .main {
    background-color: #fff !important;
    border: none;

    /deep/ .el-menu-item,
    /deep/ .el-submenu__title {
      color: #0f2e56;
    }
  }
}

.main:hover {
  background-color: #fff !important;
  border: none;

  /deep/ .el-menu-item,
  /deep/ .el-submenu__title {
    color: #000;
  }
}

/deep/ .el-submenu:hover .el-submenu__title:hover,
/deep/ .el-menu--horizontal,
.el-menu--horizontal .el-menu-item:not(.is-disabled):hover {
  color: #0f2e56;
}

.el-menu-item:not(.is-disabled):hover {
  color: #000;
}

/deep/ .el-submenu__title:hover,
/deep/ .el-submenu__title:focus,
.el-submenu__title:hover,
/deep/ .el-menu--horizontal > .el-menu-item:not(.is-disabled):focus,
.el-menu--horizontal > .el-menu-item:not(.is-disabled):hover,
.el-menu--horizontal > .el-submenu .el-submenu__title:hover {
  background-color: transparent !important;
}

.el-menu-item {
  padding: 0 30px;
}

.logo:first-child {
  padding-left: 0;
  //margin-right: 40px;
}

.el-menu--horizontal {
  /deep/ .el-menu--popup {
    left: 400px;
  }
}

.submenu {
  padding: 10px 20px;

  li {
    height: 24px;
    color: #333;
    line-height: 24px;
    outline: 0;
    word-break: keep-all;
    white-space: nowrap;
  }

  li:hover {
    transition: all 0.5s ease 0s;
    transform: translate(0, -2px);
  }

  .title {
    margin-bottom: 10px !important;
    height: 47px !important;
    font-size: 16px !important;
    font-weight: 600;
    position: relative;
  }

  .title:after {
    content: "";
    display: block;
    bottom: 0;
    left: 0;
    width: 126px;
    height: 1px;
    margin: 10px 0;
    background: linear-gradient(
      to right,
      rgba(10, 35, 73, 0.3) 0,
      transparent 100%
    );
  }

  .txt {
    span {
      vertical-align: middle;
    }

    img {
      vertical-align: middle;
      margin: 0;
    }
  }

  img {
    //width: ;
    height: 12px;
    margin: 10px 10px 0 10px;
  }

  img:hover {
    transition: all 0.5s ease 0s;
    transform: translate(0, -2px);
  }
}

.el-menu--popup-bottom-start:hover .main {
  background-color: #000 !important;
}
</style>
